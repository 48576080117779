import { keepPreviousData, useQuery } from '@tanstack/react-query';
import fitxAxiosServices from 'utils/fitx-axios';
import { GymDetails } from '~/types/gymDetails';

export const getCurrGymDetails = async () => {
  const { data } = await fitxAxiosServices.get<GymDetails>('/api/UserManager/getGymDetails');
  return data;
};

export const useGetGymDetails = (enabled: boolean = true) => {
  const query = useQuery({
    queryKey: ['gymDetails'],
    queryFn: getCurrGymDetails,
    placeholderData: keepPreviousData,
    enabled
  });

  return { query };
};

export const getGymSmsBalance = async () => {
  const { data } = await fitxAxiosServices.get<number>('/api/Communication/GetSmsBalance');
  return data;
};

export const gymSMSBalanceRootKey = 'gymSmsBalance';
export const useGetGymSmsBalance = () => {
  const query = useQuery({
    queryKey: [gymSMSBalanceRootKey],
    queryFn: getGymSmsBalance,
    placeholderData: keepPreviousData
  });

  return { query };
};
