'use client';

import { useEffect } from 'react';
import Error500 from 'views/maintenance/500';
import { useLogger } from '~/utils/Logger/useLogger';

const ErrorPage = ({ error }: { error: Error }) => {
  const logger = useLogger();

  useEffect(() => {
    logger.fatal(error);
  }, [error, logger]);

  return <Error500 />;
};

export default ErrorPage;
