import { captureException, SeverityLevel, withScope } from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { ConsoleLogger } from './ConsoleLogger';
import { ILogger } from './ILogger';

export class SentryLogger implements ILogger {
  private consoleLogger = new ConsoleLogger();

  constructor(private payload: Record<string, unknown>) {}

  private log(error: Error, level: SeverityLevel) {
    withScope((scope) => {
      scope.setLevel(level);
      if (this.payload) {
        Object.entries(this.payload).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
      }
      captureException(error);
    });
  }

  public fatal(error: Error) {
    this.consoleLogger.fatal(error);
    this.log(error, 'fatal');
  }

  public error(error: Error) {
    // don't log axios error with isSuccess false
    if (error instanceof AxiosError && error.response?.data?.isSuccess === false) {
      return;
    }

    this.consoleLogger.error(error);
    this.log(error, 'error');
  }
}
