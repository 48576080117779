import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import { useGetGymDetails } from '~/api/getGymDetails';
import { ConsoleLogger } from './ConsoleLogger';
import { ILogger } from './ILogger';
import { SentryLogger } from './SentryLogger';

export const useLogger = () => {
  const { data: session } = useSession();
  const isLoggedIn = !!session;

  const {
    query: { data: gymDetails }
  } = useGetGymDetails(isLoggedIn);

  const username = useMemo(() => gymDetails?.userName, [gymDetails]);
  const gymName = useMemo(() => gymDetails?.gymName, [gymDetails]);
  const userRoles = useMemo(() => (session?.roles ? session.roles.join(',') : ''), [session?.roles]);
  const payload = useMemo(() => ({ userRoles, username, gymName }), [userRoles, username, gymName]);

  const logger: ILogger = useMemo(
    () =>
      process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV === 'production' ? new SentryLogger(payload) : new ConsoleLogger(),
    [payload]
  );

  return logger;
};
