/* eslint-disable no-console */
import { ILogger } from './ILogger';

export class ConsoleLogger implements ILogger {
  public fatal(error: Error) {
    console.error(error);
  }

  public error(error: Error) {
    console.error(error);
  }
}
